import { useRequest } from "ahooks";
import { Form, FormInstance, Select } from "antd";
import classNames from "classnames";
import { isEqual } from "lodash-es";
import { FC, useEffect, useRef, useState } from "react";
import { getCommonOptionsByApi, ICommonFormInfoRes, ICommonOption } from "../../api/common";
import { ICommonFilterTableSearch } from "../../api/types";

const InnerApiList: FC<
  ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
    transformOption?: {
      value: string;
      label: string;
    };
    formParams?: ICommonFormInfoRes['default_params'];
    form?: FormInstance
  }
> = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
    transformOption?: {
      value: string;
      label: string;
    };
    formParams?: ICommonFormInfoRes['default_params'];
    form?: FormInstance
  }
) => {
    const lists = props.list || props.dict_list || [];
    const [options, setOptions] = useState<Array<ICommonOption>>(
      lists.map((item) => {
        if (typeof item === "string") {
          return {
            id: item,
            name: item,
          };
        } else {
          return item;
        }
      })
    );

    const { data, run: getOptionsRun } = useRequest(
      async (params?: Record<string, any>) => {
        if (props.list_api) {
          if (props.deps && Array.isArray(props.deps)) {
            let needParamKeys: string[] = []
            let match = null
            const pattern = /\{([^\{]+)\}/gis
            while ((match = pattern.exec(props.list_api)) !== null) {
              if (match && match[1]) {
                needParamKeys.push(match[1])
              }
            }
            for (let i = 0; i < needParamKeys.length; i++) {
              if (!params || params[needParamKeys[i]] == null) {
                return
              }
            }

            let res = await getCommonOptionsByApi(props.list_api, params);
            let options = res.data;
            if (props.transformOption) {
              options = options.map((option: any) => {
                return {
                  id: option[props?.transformOption?.value || "id"],
                  name: option[props?.transformOption?.label || "name"],
                };
              });

            }
            setOptions(options);
            // if (props.filterBy[props.propKey]) {
            //   debugger
            //   handleChange(props.filterBy[props.propKey])
            // }
          } else {
            // 没有依赖 可以直接请求
            let res = await getCommonOptionsByApi(props.list_api);
            let options = res.data;
            if (props.transformOption) {
              options = options.map((option: any) => {
                return {
                  id: option[props?.transformOption?.value || "id"],
                  name: option[props?.transformOption?.label || "name"],
                };
              });
            }
            setOptions(options);
          }
        }
      },
      {
        manual: true,
      }
    );
    // const getDefauptDepMap = () => {
    //   if (props.formParams) {
    //     if (props.deps?.length) {
    //       return props.deps.reduce((prev, current) => {
    //         if (props.formParams && props.formParams[current] != null) {
    //           prev[current] = props.formParams[current]
    //         }
    //         return prev
    //       }, {} as any)
    //     } else {
    //       return {}
    //     }
    //   } else {
    //     return {}
    //   }
    // }
    const [depMap, setDepMap] = useState<Record<string, any>>({});
    // const depMapRef = useRef({} as Record<string, any>)
    // const setDepMap = (depMap: any) => {
    //   depMapRef.current = depMap
    // }
    // if (props.propKey === 'course_stage_id') {
    //   debugger
    // }

    // useEffect(() => {
    //   if (props.formParams) {
    //     if (props.propKey === 'course_stage_id') {
    //       debugger
    //     }
    //     setDepMap(props.formParams)
    //   }
    // }, [props.formParams])

    useEffect(() => {
      if (props.deps?.length) {
        const tmpObj: Record<string, any> = {};
        props.deps.forEach((dep) => {
          tmpObj[dep] = props.filterBy[dep];
        });
        // 刷新存储
        // 判断tmpObj数据是否变化，变化则需要判断是否重新请求
        if (!isEqual(tmpObj, depMap)) {
          // if (props.propKey === 'course_stage_id') {
          //   debugger
          // }
          // 判断
          let canRequest = true;
          props.deps.forEach((dep) => {
            // TODO 这里有问题，不是等于空，应该随有变化，就要重新请求
            if (props.filterBy[dep] !== depMap[dep]) {
              // 清空值
              if (props.form && (props.form as any).setFieldsValueIng !== true) {
                // 如果是依赖的默认值赋值，不应该清空当前的值
                props.onChange && props.onChange(typeof props.filterBy[props.propKey] === 'string' ? '' : undefined);
              }
            }
            if (props.filterBy[dep] == null) {
              canRequest = false;
            }
            if (canRequest) {
              getOptionsRun(tmpObj);
            } else {
              // 清空
              setOptions([]);
            }
          });

          setDepMap(tmpObj);
        }
      }
    }, [props.filterBy]);

    //   useEffect(() => {
    //     // 数据变化
    //     // 清楚数据
    //     if (props.deps?.length) {
    //     //   if (props.onChange) {
    //     //     props.onChange(undefined);
    //     //   }
    //       getOptionsRun(depMap);
    //     }
    //   }, [depMap]);

    useEffect(() => {
      getOptionsRun();
      // if (props.default) {
      //   props.onChange && props.onChange(props.default)
      // }
    }, []);
    const handleChange = (newValue: any) => {
      if (props.onChange) {
        props.onChange(newValue);
      }
    };
    const handleFilterOption: any = (
      inputValue: string,
      option: Record<string, string>
    ) => {
      if (!inputValue) return;
      return (option.label || option.children)
        .toLowerCase()
        .includes(inputValue.toLowerCase());
    };
    return (
      <>
        <div data-value={props.filterBy[props.propKey]} data-key={props.propKey}>
          <Select
            style={{ width: "100%" }}
            onChange={handleChange}
            showSearch={true}
            filterOption={handleFilterOption}
            value={props.filterBy[props.propKey]}
            placeholder={props.display || "Please Select"}
          >
            {options.map((option) => {
              return <Select.Option key={option.id}>{option.name}</Select.Option>;
            })}
          </Select>
        </div>

      </>
    );
  };

const ApiList: FC<
  ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
> = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
    formParams?: ICommonFormInfoRes['default_params'];
  }
) => {
    const className = classNames({
      "no-label": !props.prefix
    });
    return (
      <Form.Item
        style={{
          width: props.width === 0 ? "initial" : (props.width || 200) + "px",
        }}
        rules={[
          {
            required: props.required,
          },
        ]}
        label={props.prefix}
        className={className}
        name={props.propKey}
      >
        <InnerApiList {...props}></InnerApiList>
      </Form.Item>
    );
  };

export default ApiList;
