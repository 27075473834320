import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { produce } from "immer";
import { nanoid } from "nanoid";
export interface IMenu {
  title: string;
  path: string;
  id: string;
}
export type MenuStateType = {
  menus: Array<IMenu>;
  currentPath: string;
  cacheKeys: string[];
};

export const initMenuState: MenuStateType = {
  menus: [
    {
      title: "首页",
      path: "/",
      id: nanoid(5),
    },
  ],
  currentPath: "",
  cacheKeys: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initMenuState,
  reducers: {
    setCurrentPath: produce(
      (draft: MenuStateType, action: PayloadAction<string>) => {
        draft.currentPath = action.payload;
      }
    ),
    resetMenu: produce(
      (draft: MenuStateType, action: PayloadAction<Array<IMenu>>) => {
        if (!action.payload.length) {
          return initMenuState;
        }
        draft.menus = action.payload;
      }
    ),
    addMenu: produce((draft: MenuStateType, action: PayloadAction<IMenu>) => {
      const index = draft.menus.findIndex((menu) => {
        return (
          menu.title === action.payload.title &&
          menu.path === action.payload.path
        );
      });
      if (index > -1) {
        draft.currentPath = action.payload.path;
      } else {
        draft.menus.push(action.payload);
        draft.currentPath = action.payload.path;
      }
    }),
    removeCurrent: produce((draft: MenuStateType) => {
      const { menus } = draft;
      const index = menus.findIndex((menu) => {
        return menu.path === draft.currentPath;
      });
      if (index > -1) {
        menus.splice(index, 1);
        if (index === 0) {
          // 首个
          draft.currentPath = menus[0].path;
        } else {
          draft.currentPath = menus[index - 1].path;
        }
      }
    }),
    removeMenu: produce(
      (draft: MenuStateType, action: PayloadAction<string>) => {
        const { menus } = draft;
        const index = menus.findIndex((menu) => menu.id === action.payload);
        if (index > -1) {
          menus.splice(index, 1);
          if (index === 0) {
            // 首个
            draft.currentPath = menus[0].path;
          } else {
            draft.currentPath = menus[index - 1].path;
          }
        }
      }
    ),
    addCacheKeys: produce(
      (draft: MenuStateType, action: PayloadAction<string>) => {
        debugger
        if (!draft.cacheKeys.includes(action.payload)) {
          draft.cacheKeys.push(action.payload);
        }
      }
    ),
    removeCacheKeys: produce(
      (draft: MenuStateType, action: PayloadAction<string>) => {
        draft.cacheKeys = draft.cacheKeys.filter(
          (item) => item != action.payload
        );
      }
    ),
    clearCacheKeys: produce((draft: MenuStateType) => {
      draft.cacheKeys = [];
    }),
  },
});

export const {
  setCurrentPath,
  resetMenu,
  removeMenu,
  addMenu,
  removeCurrent,
  addCacheKeys,
  removeCacheKeys,
  clearCacheKeys,
} = menuSlice.actions;

export default menuSlice.reducer;
