import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";

// export interface IMedia {
//   showMediaDialog: boolean;
// }

export interface IMedia {
  filename: string;
  icon_url: string;
  media_id: string | null;
  title: string;
  url: string;
}

export type MediaStateType = {
    showMediaDialog: boolean,
    media_api: string;
    callback?: (media: IMedia) => void
}

export const initMediaState: MediaStateType = {
  showMediaDialog: false,
  media_api: '',
  callback: undefined
};



export const mediaSlice = createSlice({
  name: "media",
  initialState: initMediaState,
  reducers: {
    openMediaDialog: produce((draft: MediaStateType, action: PayloadAction<{
      media_api: string,
      callback?: (media: IMedia) => void
    }>) => {
      draft.showMediaDialog = true;
      draft.media_api = action.payload.media_api
      if (action.payload.callback) {
        draft.callback = action.payload.callback
      }
    }),
    closeMediaDialog: produce((draft: MediaStateType, action: PayloadAction<IMedia | null>) => {
      draft.showMediaDialog = false;
      if (action?.payload) {
        draft.callback && draft.callback(action?.payload)
      }
      draft.media_api = ''

    }),
  },
});

export const { openMediaDialog, closeMediaDialog } = mediaSlice.actions;

export default mediaSlice.reducer;
