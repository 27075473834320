import { FC } from "react";
import { Outlet } from "react-router";
import Dialogs from "../components/dialogs/Dialogs";
import { GlobalLoading } from "../components/global-loading/GlobalLoading";
import LoadingComponent from "../components/loading/Loading";
import MediaDialog from "../components/media-dialog/MediaDialog";

const RootLayout: FC = () => {
  return (
    <>
      <Outlet />
      <MediaDialog></MediaDialog>
      <Dialogs></Dialogs>
      <LoadingComponent/>
      <GlobalLoading />
    </>
  );
};

export default RootLayout;
