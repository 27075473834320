import { Button, Col, Form, Input, message, Row, Space } from "antd";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStudentCourseInfo, ISaveStudentParams, IStudentCourseInfoRes, IStudentCourseWaiting, saveStudentApi } from "../../api/student";
import SelectSchoolApplyCourse from "./SignupIndex/SelectSchoolApplyCourse";
import styles from "./Signup.module.scss";
import SignupSearch from "./SignupIndex/SignupSearch";
import { useDialog } from "../../hooks/useDialog";
import RegisterNewStudent from "./SignupIndex/RegisterNewStudent";
import { useMenuTag } from "../../hooks/useMenuTag";

const Signup = () => {
  const dialog = useDialog();
  const defaultStudentCourseInfo: IStudentCourseInfoRes = {
    student_info: {
      student_id: "",
      name: "",
      school: "",
      grade: "",
    },
    enrolled_list: [],
    waiting_list: [],
    schools: [],
  };

  const { goRouteAndAddTag } = useMenuTag();
  
  const [studentCourseInfo, setStudentCourseInfo] =
    useState<IStudentCourseInfoRes>(defaultStudentCourseInfo);
  const [newStudentGrade, setNewStudentGrade] = useState("");

  const signupSearchRef = useRef<typeof SignupSearch>()

  const handleSearch = async (values: {
    student_id: string;
    qr_code: string;
    new_student_grade: string;
  }) => {
    try {
      // 0d4e75ed1c9e497f8c8ef5625fa70096
      let params = {
        student_id: values.student_id,
        qr_code: values.qr_code,
        new_student_grade: values.new_student_grade,
      };
      if (process.env.NODE_ENV === "development") {
        params.student_id = "0d4e75ed1c9e497f8c8ef5625fa70096";
      }
      let res = await getStudentCourseInfo(params);
      setStudentCourseInfo(res.data);
      setNewStudentGrade(values.new_student_grade);
    } catch (e) {
      console.error(e);
      message.error("Error");
    }
  };

  const handleClear = () => {
    setStudentCourseInfo(defaultStudentCourseInfo);
  };

  const registerNewStudentRef = useRef<typeof RegisterNewStudent>();
  const handleRegisterNewStudent = () => {
    const content = <RegisterNewStudent ref={registerNewStudentRef} />;
    dialog.openDialog({
      title: "Create New Student",
      onOk: async (done: Function) => {
        if (registerNewStudentRef.current) {
          const { validateFields, getFormData } = (
            registerNewStudentRef.current as any
          ).getCommonFormInstance();
          try {
            await validateFields();
            const formData: ISaveStudentParams = getFormData();
            try {
                let res = await saveStudentApi({
                    school_id: formData.school_id,
                    login_name: formData.login_name,
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    nickname: formData.nickname,
                    school: formData.school,
                    student_grade: formData.student_grade,
                    gender: formData.gender,
                    mobile: formData.mobile,
                    address_district: formData.address_district || '',
                    address_city: formData.address_city || '',
                    birthday: formData.birthday,
                    email: formData.email,
                    address_room: formData.address_room || '',
                    address_floor: formData.address_floor || '',
                    address_building: formData.address_building || '',
                    address_street_estate: formData.address_street_estate || '',
                    hobbies: formData.hobbies || '',
                    strengths: formData.strengths || '',
                    weaknesses: formData.weaknesses || ''
                })
                setStudentCourseInfo(info => {
                    return {
                        ...info,
                        student_info: {
                            ...info.student_info,
                            student_id: res.data.student_id
                        }
                    }
                })
                // 刷新下拉框
                await (signupSearchRef.current as any)?.reloadSelectStudent(res.data.student_id)
                done();
            } catch (e: any) {
                console.error(e)
                message.error(e?.response?.data?.detail ||  "Error")
            }
          } catch {}
        }
      },
      content,
    });
  };

  const handleWaitingAssignClick = (waitingItem: IStudentCourseWaiting) => {
    goRouteAndAddTag('/assign-class/' + waitingItem.waiting_id, 'Assign Class')
  }

  return (
    <div
      className={classNames({
        [styles.page]: true,
        ["signup-page"]: true,
      })}
    >
      <SignupSearch
      ref={signupSearchRef}
        onSearch={handleSearch}
        onClear={handleClear}
        onRegisterNewStudent={handleRegisterNewStudent}
      ></SignupSearch>
      {studentCourseInfo.student_info.name && (
        <>
          <Row>
            <div className={styles.blockTitle}>Student Infomation</div>
            <Col span={8}>
              <div className={styles.blockItem}>
                <div className={styles.title}>Student</div>
                <div className={styles.content}>
                  {studentCourseInfo.student_info.name}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.blockItem}>
                <div className={styles.title}>School</div>
                <div className={styles.content}>
                  {studentCourseInfo.student_info.school}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.blockItem}>
                <div className={styles.title}>Grade</div>
                <div className={styles.content}>
                  {studentCourseInfo.student_info.grade}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className={styles.blockTitle}>Courses Enrolled</div>
            <Col span={24}>
              {studentCourseInfo.enrolled_list.map((enrolledItem) => {
                return (
                  <Row key={enrolledItem.course_sale_id}>
                    <Col span={12}>
                      <div className={styles.blockItem}>
                        <div className={styles.title}>
                          {enrolledItem.course}
                        </div>
                        <div className={styles.content}>
                          {enrolledItem.stage}
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.blockItem}>
                        <div className={styles.title}>Payment Due Date</div>
                        <div className={styles.content}>
                          <span
                            style={{ color: enrolledItem.tip ? "red" : "" }}
                          >
                            {enrolledItem.payment_due_date}
                          </span>
                        </div>
                        <div className={styles.buttons}>
                          <Button type="primary" size="small">
                            Renew
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
          <Row>
            <div className={styles.blockTitle}>Waiting List</div>
            <Col span={24}>
              <Row>
                {studentCourseInfo.waiting_list.map((waitingItem) => {
                  return (
                    <Col span={24} key={waitingItem.waiting_id}>
                      <div className={styles.blockItem}>
                        <div className={styles.title}>{waitingItem.type}</div>
                        <div className={styles.content}>
                          {waitingItem.course}
                        </div>
                        <div className={styles.buttons}>
                          <Button type="primary" size="small" onClick={() => handleWaitingAssignClick(waitingItem)}>
                            Assign
                          </Button>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <SelectSchoolApplyCourse
            student_id={studentCourseInfo.student_info.student_id}
            students={studentCourseInfo.schools}
            new_student_grade={newStudentGrade}
          ></SelectSchoolApplyCourse>
        </>
      )}
    </div>
  );
};

export default Signup;
