import { Axios, AxiosResponse } from "axios";
import { ICommonFormInfoRes } from "./common";

export interface ICommonFilterTableColumn {
  column_name: string;
  column_value: string;
  width?: number;
  can_order: boolean;
}

export interface ICommonFilterTablePaginationRes {
  columns: Array<ICommonFilterTableColumn>;
  data: Array<Record<string, string>>;
  page_num: number;
  per_page_num: number;
  total_num: number;
  total_page_num: number;
}

export interface ICommonFilterTablePagination extends Record<string, any> {
  page_num: number;
  per_page_num?: number;
}

// export interface ICommonFilterTablePaginationReq {
//   pagination: ICommonFilterTablePagination & {
//     order_by?: Record<string, number>;
//     filter_by?: Record<string, any>;
//   };
// }

export interface ICommonFilterTablePaginationReq
  extends ICommonFilterTablePagination {
  order_by?: Record<string, number>;
  filter_by?: Record<string, any>;
}

export interface ICommonFilterTablePaginationApi {
  (params: ICommonFilterTablePaginationReq, api?: string): Promise<
    AxiosResponse<ICommonFilterTablePaginationRes>
  >;
}

export type ICommonFilterOperatorComponentCrudType = {
  info_api: string;
  type: string;
};
export type ICommonFilterOperatorComponentType =
  ICommonFilterOperatorComponentCrudType;
export interface ICommonFilterOperator {
  name: string;
  title: string;
  type: CommonFilterOperationEnum;
  component?: ICommonFilterOperatorComponentType;
  redirect_url?: string;
  open_new_tab?: string;
  deps?: Array<string>;
}

export enum CommonFilterOperationEnum {
  ALWAYS_SHOW = "always show",
  SELECTED_ONE_ONLY = "selected one only",
  SELECTED = "selected",
}

export interface ICommonFilterTableSearchApi {
  (api?: string): Promise<
    AxiosResponse<{
      filters: Array<ICommonFilterTableSearch>;
      operators: Array<ICommonFilterOperator>;
    }>
  >;
}

export interface ICommonFilterTableSearch {
  type: string;
  name: string;
  prefix: string;
  display: string;
  list_api: string;
  dict_list?: Array<{
    id: string;
    name: string;
  }>;
  param: string;
  key: string;
  value_type?: string;
  list: Array<string>;
  onChange: (newValue: any) => void;
  propKey: string;
  deps?: Array<string>;
  default?: any;
  width?: number | string;
  required?: boolean;
  media_api?: string;
}

export enum ICommonFilterTableSearchType {
  ApiList = "api list",
  Keyword = "keyword",
  Date = "date",
  EnumList = "enum list",
  Int = "int",
  Time = "time",
  Textarea = "textarea",
  Upload = "upload",
  DicList = 'dict list',
  Media = 'media'
}

export interface IAssignInfoRes {
  api: string;
  assigned_list: Array<{
    id: string;
    name: string;
  }>;
  assigned_title: string;
  default_target_id: string;
  prefix: string;
  search_assigned_display: string;
  search_unassigned_display: string;
  target_id: string;
  target_name: string;
  targets: Array<{
    id: string;
    name: string;
    target_id: string;
    target_name: string;
  }>;
  title: string;
  unassigned_title: string;
  unassigned_list: Array<{
    id: string;
    name: string;
  }>;
}

export interface ICommonFormInfoItem extends ICommonFilterTableSearch {}

export interface ICommonFormInfoGetPromise {
  (api: string): Promise<AxiosResponse<ICommonFormInfoRes>>;
}
