import { Button, message, Row, Space, Table, TableColumnProps } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  getStudentCourseMasterStagesApi,
  getStudentCourseStagesApi,
  IGetStudentCourseMasterStagesRes,
  IGetStudentCourseStagesRes,
  IStuentCourseMasterStage,
  IStuentCourseStage,
} from "../../../api/student";
import { useDialog } from "../../../hooks/useDialog";
import styles from "../Signup.module.scss";
import ApplyStudentCourseStagePackage from "./ApplyStudentCourseStagePackage";

export interface IApplyStudentCourseStage {
  course_id: string;
  student_id: string;
  new_student_grade: string;
  school_id: string;
  onAppoint: Function;
}

const ApplyStudentCourseStage = (props: IApplyStudentCourseStage) => {
  const dialog = useDialog();
  const [studentCourseStage, setStudentCourseState] =
    useState<IGetStudentCourseStagesRes>({
      selected: "",
      stages: [],
    });

  const getData = async () => {
    try {
      let res = await getStudentCourseStagesApi({
        student_id: props.student_id,
        new_student_grade: props.new_student_grade,
        course_id: props.course_id,
        school_id: props.school_id
      });
      setStudentCourseState(res.data);
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    }
  };

  const columns: ColumnsType<IStuentCourseStage> = [
    {
      title: "Stage",
      key: "stage",
      dataIndex: "stage",
    },
    {
      title: "Stage Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Student Levels",
      key: "student_level",
      dataIndex: "student_level",
    },
    {
      title: "No. of Lessons",
      key: "lesson_num",
      dataIndex: "lesson_num",
    },
    {
      title: "Lesson Duration",
      key: "lesson_duration",
      dataIndex: "lesson_duration",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, item) => {
        return (
          item.can_appoint && (
            <Button type="primary" size="small" onClick={() => handleAppoint()}>
              Appoint
            </Button>
          ),
          item.can_apply ? (
            <Button
              type="primary"
              size="small"
              onClick={() => handleApply(item)}
            >
              Apply
            </Button>
          ) : (
            <Button disabled type="default" size="small">
              Not Available
            </Button>
          )
        );
      },
    },
  ];

  const handleAppoint = () => {
    props.onAppoint();
  };

  const handleApply = (item: IStuentCourseStage) => {
    dialog.openDialog({
      title: "Sale Packages",
      content: (
        <ApplyStudentCourseStagePackage
          student_id={props.student_id}
          new_student_grade={props.new_student_grade}
          course_stage_id={item.course_stage_id}
          onAppoint={handleAppoint}
          school_id={props.school_id}
        />
      ),
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className={classNames({
        [styles.page]: true,
        ["signup_apply_student_course_stage"]: true,
      })}
    >
      <Row>
        <div className={styles.blockTitle}>{studentCourseStage.selected}</div>
        <div className={styles.blockTitle}>Stages</div>
        <Table
          columns={columns}
          dataSource={studentCourseStage.stages}
          rowKey="course_id"
        ></Table>
      </Row>
    </div>
  );
};

export default ApplyStudentCourseStage;
