import { FC, useEffect } from "react";
import styles from "./Login.module.scss";
import Footer from "./Footer";
import {
  Layout,
  Typography,
  Form,
  Space,
  Input,
  Checkbox,
  Button,
  message,
} from "antd";
// import logoImg from "../../assets/login/Logo.svg";
import logoImg from "../../assets/login/logo@2x.png";
import zhanghaoImg from "../../assets/login/zhanghao.svg";
import mimaImg from "../../assets/login/mima.svg";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useRequest } from "ahooks";
import { loginApi, permissionApi } from "../../api/auth";
import { setToken } from "../../utils/token";
import { AxiosError } from "axios";
import { setUser } from "../../utils/user";
import { useApp } from "../../context/app-context";
const { Title, Text } = Typography;

const Login: FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const nav = useNavigate();
  const app = useApp()

  useEffect(() => {
    const { login_name, password } = getUserInfoFromStorage();
    form.setFieldsValue({
      login_name,
      password,
    });
    if (login_name) {
      form.setFieldValue("remember", true);
    }
    if (!login_name && process.env.NODE_ENV === "development") {
      form.setFieldsValue({
        login_name: "frankie",
        password: "1",
      });
    }
  }, []);

  const remberUser = (login_name: string, password: string) => {
    localStorage.setItem(
      "login_user",
      JSON.stringify({ login_name, password })
    );
  };

  const getUserInfoFromStorage = () => {
    return JSON.parse(
      localStorage.getItem("login_user") ||
        JSON.stringify({ login_name: "", password: "" })
    );
  };
  const deleteUserFromStorage = () => {
    localStorage.removeItem("login_user");
  };
  const { run: permissionRequest, loading: loadingPermission } = useRequest(
    permissionApi,
    {
      manual: true,
      onSuccess(result) {
        debugger;
        setUser(result.data);
        message.success("Success");
        nav("/");
      },
      onFinally() {
        debugger;
      },
    }
  );

  const { run: loginRequest, loading } = useRequest(loginApi, {
    manual: true,
    onSuccess(result) {
      //   const { token } = result;
      setToken(result.data.x_token);
      // permissionRequest(result.data.x_token)
      permissionApi().then((res) => {
        app.setUser(res.data)
        setUser(res.data);
        nav("/");
      });
      // TODO

      // message.success("Success");
    },
    onError(e, params) {
      message.error((e as AxiosError<any>)?.response?.data.detail);
    },
  });

  const onFinish = (values: {
    login_name: string;
    password: string;
    remember: string;
  }) => {
    const { login_name, password, remember } = values;
    loginRequest({ login_name, password });
    if (values.remember) {
      remberUser(login_name, password);
    } else {
      deleteUserFromStorage();
    }
  };

  return (
    <div className={styles.page}>
      <Layout className={styles.formContainer}>
        <Space direction="vertical" align="center">
          <img src={logoImg} alt="" width={300}/>
          <Title level={4}>{t("Login.title")}</Title>
        </Space>
        <Space >
          <img height={30}/>
        </Space>
        <Form
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="login_name"
            label=""
            rules={[
              {
                required: true,
                message: t("Login.phonePlaceholder"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("Login.phonePlaceholder")}
              prefix={<img src={zhanghaoImg} />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label=""
            rules={[
              {
                required: true,
                message: t("Login.passwordPlaceholder"),
              },
            ]}
          >
            <Input
              size="large"
              type="password"
              placeholder={t("Login.passwordPlaceholder")}
              prefix={<img src={mimaImg} />}
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>{t("Login.remberAccont")}</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block={true}
              loading={loading || loadingPermission}
            >
              {t("Login.login")}
            </Button>
          </Form.Item>
        </Form>
        <Text className={styles.tip}>{t("Login.loginTip")}</Text>
      </Layout>
      <Footer></Footer>
    </div>
  );
};

export default Login;
