import styles from "../Signup/Signup.module.scss";
import classNames from "classnames";
import { Button, Col, DatePicker, Input, message, Row } from "antd";
import { useDialog } from "../../hooks/useDialog";
import { useEffect, useState } from "react";
import {
  getTranactionPaymentInfoApi,
  ITranactionPaymentInfoRes,
  transactionPaymentApi,
} from "../../api/classes";
import dayjs, { Dayjs } from "dayjs";
import CommonSelect from "../../components/common-select/CommonSelect";
import { ICommonOption } from "../../components/common-select/types";
import { useApp } from "../../context/app-context";

export interface IConfirmPaymentProps {
  transaction_id: string;
}
const ConfirmPayment = (props: IConfirmPaymentProps) => {
  const dialog = useDialog();
  const app = useApp();
  const [transactionInfos, setTransactionInfos] = useState<
    ITranactionPaymentInfoRes["transaction_info"]
  >([]);
  const [paymentMethods, setPaymentMethods] = useState<Array<ICommonOption>>(
    []
  );
  const [date, setDate] = useState<null | Dayjs>(dayjs());
  const [method, setMethod] = useState("");

  const [invitation_code, setInvitation_code] = useState('');

  const init = async () => {
    try {
      let res = await getTranactionPaymentInfoApi(props.transaction_id);
      setPaymentMethods(
        res.data.payment_methods.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
      setTransactionInfos(res.data.transaction_info);
      setInvitation_code(res.data.invitation_code);
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    }
  };

  const handleConfirm = async () => {
    try {
      app.setLoading(true);
      let res = await transactionPaymentApi({
        invitation_code: invitation_code,
        transaction_id: props.transaction_id,
        payment_date:
          date?.format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
        payment_method: method,
      });
      message.success(res.data.detail);
      dialog.closeAllDialog();
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    } finally {
      app.setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div
      className={classNames({
        ["assign-class-confirmpayment-page"]: true,
        [styles.page]: true,
      })}
    >
      <Row>
        <div
          className={classNames({
            [styles.blockTitle]: true,
            "search-title": true,
          })}
        >
          Payment
        </div>
        {transactionInfos.map((transactionInfo, index) => {
          return (
            <Col span={24} key={index}>
              <div className={styles.blockItem}>
                <div className={styles.title}>{transactionInfo.title}</div>
                <div className={styles.content}>{transactionInfo.content}</div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row style={{ paddingTop: "10px" }}>
        <span>Payment Date:&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <DatePicker
          allowClear={false}
          value={date}
          onChange={(newDate) => setDate(newDate)}
        ></DatePicker>
      </Row>
      <Row style={{ paddingTop: "10px" }}>
        <span>Payment Methods:&nbsp;&nbsp;</span>
        <CommonSelect
          width={"120px"}
          allowClear={false}
          options={paymentMethods}
          onChange={(m) => setMethod(m as string)}
        ></CommonSelect>
      </Row>
      <Row style={{ paddingTop: "10px" }}>
        <span>Invitation Code:&nbsp;&nbsp;</span>
        <Input style={{ width: '120px' }}  value={invitation_code} onChange={(e) => setInvitation_code(e.target.value)}/>
        
      </Row>
      <Row justify={"end"} style={{ paddingTop: "10px" }}>
        <Button type="primary" onClick={handleConfirm} disabled={!method}>
          Confirm Payment
        </Button>
      </Row>
    </div>
  );
};

export default ConfirmPayment;
