import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Tooltip } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ICommonFilterTableSearch } from "../../../api/types";
import { IMedia, openMediaDialog } from "../../../store/mediaReducer";

const Media = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
) => {
  const className = classNames({
    "no-label": !props.prefix,
  });
  

  return (
    <Form.Item
      style={{
        width: props.width === 0 ? "initial" : (props.width || 200) + "px",
      }}
      rules={[
        {
          required: props.required,
        },
      ]}
      label={props.prefix}
      className={className}
      name={props.propKey}
    >
      <Inner {...props}></Inner>
    </Form.Item>
  );
};

const Inner = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
) => {
  const [medias, setMedias] = useState<
    Array<{
      url: string;
      media_id: string;
    }>
  >([]);
  const dispatch = useDispatch();

  const handleClick = () => {
    // 打开图片管理器
    if (props.media_api) {
        dispatch(openMediaDialog({
            media_api: props.media_api,
            callback: (selectedMedia: IMedia) => {
              handleAdd(selectedMedia)
            }
        }))
    }
  }

  const handleAdd = (selectedMedia: IMedia) => {
    const currentFiles = [selectedMedia].map(item => {
      return {
        url: item.url,
        media_id: item.media_id
      }
    });
    const newFiles = [
      ...medias,
      ...currentFiles.map((file: any) => {
        return {
          url: file.url,
          media_id: file.media_id,
        };
      }),
    ];
    setMedias(newFiles);
    if (props.onChange) {
      props.onChange(newFiles);
    }
  };
  const handleDelete = (index: number) => {
    const newFiles = [];
    for (let i = 0; i < medias.length; i++) {
      if (i !== index) {
        newFiles.push(medias[i]);
      }
    }
    // 需要把formFiles也删除的
    setMedias(newFiles);
    if (props.onChange) {
      props.onChange(newFiles);
    }
  };

  return (
    <div className="common-form-media-item">
      <Button type="default" onClick={handleClick} className="common-form-media-item__button">
        <PlusOutlined /> {props.display}
        {/* <input
          type={"button"}
          onChange={handleChange}
          name={props.propKey}
        /> */}
      </Button>
      <div className="upload-list">
        <div className="upload-list-item-container">
          {medias.map((file, index) => {
            return (
              <div className="upload-list-item" key={file.url}>
                <Tooltip title={file.url}>
                  <div className="upload-list-item-name">{file.url}</div>
                </Tooltip>
                <span className="upload-list-item-actions">
                  <Button type="link" danger>
                    <DeleteOutlined onClick={() => handleDelete(index)} />
                  </Button>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Media;
