import { request } from "../utils/request";
import { AxiosResponse } from "axios";
import {
  ICommonFilterTablePagination,
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationReq,
  ICommonFilterTableSearch,
  ICommonFilterTableSearchApi,
} from "./types";

export interface IClassMenuRes {
  searches: Array<ICommonFilterTableSearch>;
  columns: Array<{
    column_name: string;
    column_value: string;
    width?: number;
    can_order: boolean;
  }>;
}

export const getClassesMenuApi: ICommonFilterTableSearchApi = () => {
  return request.get("/classes/pagination/menu");
};

export const getClassesByPaginationApi: ICommonFilterTablePaginationApi = (
  params: ICommonFilterTablePaginationReq
) => {
  return request.post("/classes/pagination", params);
};

export interface IBatchCreateCourseRes {
  class_num: null | number;
  course_id: string;
  lesson_begin_num: number;
  lesson_end_num: number;
  lesson_per_week: Array<number>;
  show_class_num: boolean;
  show_lesson_num: boolean;
  teachers: Array<{
    teacher_id: string;
    teacher_name: string;
  }>;
}
export const batchCreateCourseInfoApi = (
  course_id: string
): Promise<AxiosResponse<IBatchCreateCourseRes>> => {
  return request.get(`/classes/batch_create/info?course_id=${course_id}`);
};

export interface IBatchCreateCourseParam {
  mode: "preview" | "confirm";
  classroom_id: string;
  course_id: string;
  lesson_begin_num: number;
  lesson_end_num: number;
  class_num: number;
  teacher_id: string;
  date_start: string;
  time_start: string;
  lesson_per_week: number;
  weekdays: Array<string>;
  skip: Array<number>;
  delete: Array<number>;
}

export interface IBatchCreateCourseRes {
  class_schedules: Array<IClassSchedule>;
}
export interface IClassSchedule {
  begin_datetime: string;
  begin_time: string;
  conflict_details: Array<any>;
  date: string;
  end_datetime: string;
  end_time: string;
  id: number;
  is_conflicted: boolean;
  is_conflicted_str: string;
  lesson: number;
}
export const batchCreateCourseApi = (
  params: IBatchCreateCourseParam
): Promise<AxiosResponse<IBatchCreateCourseRes>> => {
  return request.post("/classes/batch_create", params);
};

export interface IAutoFillClassesParams {
  waiting_id: string;
  class_ids: string[];
  autofill_mode: string;
  unfilled_datetimes: string[];
  fill_next_stage: boolean;
}

export interface IAutoFillClassesRes {
  class_ids: string[];
  unfilled_datetimes: [];
}
export const autoFillClassesApi = (
  params: IAutoFillClassesParams
): Promise<AxiosResponse<IAutoFillClassesRes>> => {
  return request.post("/classes/autofill", params);
};

export interface IAssignClassesParams {
  waiting_id: string;
  class_ids: string[];
}
export interface IAssignClassesRes {
  discount: number | null;
  class_ids: string[];
  waiting_id: string;
  price_info: Array<{
    title: string;
    content: string;
  }>;
  detail?: string;
}

export const assignClassesApi = (
  params: IAssignClassesParams
): Promise<AxiosResponse<IAssignClassesRes>> => {
  return request.post("/classes/assign", params);
};

export interface ICalculateCourseParams {
  discount: null | number;
  waiting_id: string;
  class_ids: string[];
}
export interface ICalculateCourseRes {
  discount: null | number;
  price_info: Array<{
    title: string;
    content: string;
  }>;
}
export const calculateCoursePriceApi = (
  params: ICalculateCourseParams
): Promise<AxiosResponse<ICalculateCourseRes>> => {
  return request.post("/course_sales/price", params);
};

export interface IConfirmPaymentCourseParams extends ICalculateCourseParams {}

export const confirmPaymentCourseApi = (
  params: IConfirmPaymentCourseParams
): Promise<
  AxiosResponse<{
    detail: string;
    transaction_id: string;
  }>
> => {
  return request.post("/course_sales", params);
};

export interface ITranactionPaymentInfoRes {
  payment_methods: string[];
  invitation_code: string;
  transaction_info: Array<{
    title: string;
    content: string;
  }>;
}
export const getTranactionPaymentInfoApi = (
  transaction_id: string
): Promise<AxiosResponse<ITranactionPaymentInfoRes>> => {
  return request.get("/transactions/payment/info", {
    params: {
      transaction_id,
    },
  });
};
export interface ITransactionPaymentParams {
  transaction_id: string;
  payment_date: string;
  payment_method: string;
  invitation_code: string;
}

export const transactionPaymentApi = (
  params: ITransactionPaymentParams
): Promise<
  AxiosResponse<{
    detail: string;
  }>
> => {
  return request.patch("/transactions", params);
};
