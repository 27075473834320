import { Affix, Col, message, Row } from "antd";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useRoutes } from "react-router";
import { getClassesByPaginationApi, getClassMenuApi } from "../../api/class";
import { autoFillClassesApi } from "../../api/classes";
import { getWaitingInfoApi, IWaitingInfoRes } from "../../api/waiting";
import CommonFilterTablePagination, {
  ITableSelectedData,
} from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import { useDialog } from "../../hooks/useDialog";
import { useMenuTag } from "../../hooks/useMenuTag";
import styles from "../Signup/Signup.module.scss";
import PriceInfo from "./PriceInfo";

const AssignClass = () => {
  const { waitingId } = useParams() || "";

  const { goRouteAndAddTag } = useMenuTag();

  const pageRef = useRef<HTMLDivElement | null>(null);
  const dialog = useDialog();

  const operationFuncs: any = {
    Create: (tableData: ITableSelectedData) => {
      goRouteAndAddTag("/classes/new", "New Classes");
    },
    AutofillWeek: async (tableData: ITableSelectedData) => {
        operationFuncs.autoFillByType(tableData, 'week')
    },
    AutofillMonth: (tableData: ITableSelectedData) => {
        operationFuncs.autoFillByType(tableData, 'month')
    },
    AutofillCourse: (tableData: ITableSelectedData) => {
        operationFuncs.autoFillByType(tableData, 'course')
    },
    Clear: (tableData: ITableSelectedData) => {
        setClassesIds([])
        setUnfilledDatetimes([])
        tableData.setCheckedRowKeys([])
    },
    Confirm: (tableData: ITableSelectedData) => {
        dialog.openDialog({
            title: 'Assign Classes',
            content: <PriceInfo waitingId={waitingId as string} classesIds={classesIds}/>,
            hideFooter: true
        })
    },
    autoFillByType: async (tableData: ITableSelectedData, mode: string) => {
        try {
            let res = await autoFillClassesApi({
                waiting_id: waitingId as string,
                class_ids: classesIds,
                autofill_mode: mode,
                unfilled_datetimes: unfilledDatetimes,
                fill_next_stage: false
            })
            setClassesIds(res.data.class_ids)
            setUnfilledDatetimes(res.data.unfilled_datetimes)
            tableData.setCheckedRowKeys(res.data.class_ids)
        } catch (e: any) {
            console.error(e);
            message.error(e?.response?.data?.detail || "Error");
        }
    },
  };

  const [classesIds, setClassesIds] = useState<string[]>([]);
  const [unfilledDatetimes, setUnfilledDatetimes] = useState<string[]>([]);

  const handleTableCheckedChange = (keys: React.Key[]) => {
    setClassesIds(keys as string[]);
  };

  const [waitingInfo, setWaitingInfo] = useState<IWaitingInfoRes>({
    waiting_info: {
      id: "",
      type: "",
      remark: "",
    },
    student_info: {
      id: "",
      name: "",
      school: "",
      grade: "",
    },
    course_info: {
      school: "",
      course: "",
      stage: "",
      sale_package: "",
      finished_lessons: null,
      registered_lesson: null,
    },
  });

  const getWaitingData = async () => {
    try {
      let res = await getWaitingInfoApi(waitingId as string);
      setWaitingInfo(res.data);
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    }
  };
  useEffect(() => {
    getWaitingData();
  }, []);

  
  return (
    <div
      ref={pageRef}
      className={classNames({
        ["assign-class-page"]: true,
        [styles.page]: true,
      })}
    >
      <Row>
        <div className={styles.blockTitle}>Student Infomation</div>
        <Col span={8}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Student</div>
            <div className={styles.content}>
              {waitingInfo.student_info.name}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.blockItem}>
            <div className={styles.title}>School</div>
            <div className={styles.content}>
              {waitingInfo.student_info.school}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Grade</div>
            <div className={styles.content}>
              {waitingInfo.student_info.grade}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className={styles.blockTitle}>Course Infomation</div>
        <Col span={8}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Course</div>
            <div className={styles.content}>
              {waitingInfo.course_info.course}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Stage</div>
            <div className={styles.content}>
              {waitingInfo.course_info.stage}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Sale Package</div>
            <div className={styles.content}>
              {waitingInfo.course_info.sale_package}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Intrinsic School</div>
            <div className={styles.content}>
              {waitingInfo.student_info.school}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Finished Lessons</div>
            <div className={styles.content}>
              {waitingInfo.course_info.finished_lessons}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className={styles.blockItem}>
            <div className={styles.title}>Registered Lessons</div>
            <div className={styles.content}>
              {waitingInfo.course_info.registered_lesson}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className={styles.blockTitle}>Class Selection</div>
      </Row>
      {/* <div>{classesIds}</div> */}
      {pageRef.current && (
        // <Affix offsetTop={16}>
          <div
            className="common-filter-table-pagination-page"
            style={{
              height: (pageRef.current.offsetHeight - 100) * 2 + "px",
            }}
          >
            <CommonFilterTablePagination
              getSearchPromise={() => getClassMenuApi(waitingId)}
              getListPromise={(params) =>
                getClassesByPaginationApi(params, waitingId)
              }
              rowKey="class_id"
              operationFuncs={operationFuncs}
              onCheckedChange={handleTableCheckedChange}
            ></CommonFilterTablePagination>
          </div>
        // </Affix>
      )}
    </div>
  );
};

export default AssignClass;
