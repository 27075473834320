import { useRequest } from "ahooks";
import {
  Layout,
  Modal,
  Button,
  Tabs,
  Input,
  Form,
  Checkbox,
  Image,
} from "antd";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommonFilterTableByPaginationApi } from "../../api/common";
import { StateType } from "../../store";
import {
  closeMediaDialog,
  IMedia,
  MediaStateType,
} from "../../store/mediaReducer";
import classnames from "classnames";
import "./MediaDialog.scss";

const { Header, Footer, Sider, Content } = Layout;

const MediaDialog = () => {
  const mediaState = useSelector<StateType, MediaStateType>(
    (state) => state.media
  );
  const dispatch = useDispatch();

  const [medias, setMedias] = useState<Array<IMedia>>([]);
  const [selectedItem, setSelectedItem] = useState<null | IMedia>(null);
  const [activeTab, setActiveTab] = useState("1");

  const [form] = Form.useForm();

  const handleTabChange = (activeKey: string) => {
    setSelectedItem(null);
    setActiveTab(activeKey);
  };
  const handleInputUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedItem({
      filename: "",
      icon_url: "",
      title: "",
      media_id: null,
      url: event.target.value,
    });
  };

  const handleCheckIcon = (item: IMedia) => {
    setSelectedItem(item);
    form.setFieldsValue(item);
  };

  const handleConfirm = () => {
    if (selectedItem) {
      dispatch(closeMediaDialog(selectedItem));
    }
  };

  const handleCancel = () => {
    setSelectedItem(null);
    dispatch(closeMediaDialog(null));
  };

  useEffect(() => {
    if (mediaState.media_api) {
      getCommonFilterTableByPaginationApi(
        {
          page_num: 1,
          per_page_num: 100000,
        },
        mediaState.media_api
      ).then((res) => {
        setMedias(res.data.data as any);
      });
    } else {
      setMedias([]);
    }
  }, [mediaState.media_api]);

  return (
    <Modal
      title="Chose Media"
      mask={true}
      maskClosable={false}
      destroyOnClose={true}
      open={mediaState.showMediaDialog}
      footer={null}
      bodyStyle={{
        height: "calc(100vh - 200px)",
      }}
      width={1200}
      onCancel={handleCancel}
    >
      <div className="common-media-dialog">
        <div className="left">
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            tabPosition="left"
            items={[
              {
                label: "插入媒体",
                key: "1",
              },
              {
                label: "从网址插入媒体",
                key: "2",
              },
            ]}
          ></Tabs>
        </div>

        {activeTab === "1" ? (
          <>
            <div className="middle">
              <Button type="default" disabled style={{ width: "120px" }}>
                上传媒体
              </Button>
              <div className="content">
                {medias.map((item, index) => {
                  return (
                    <div
                      className={classnames({
                        "image-item": true,
                        active: item.media_id === selectedItem?.media_id,
                      })}
                      key={index}
                      onClick={() => handleCheckIcon(item)}
                    >
                      <img src={item.icon_url} />
                      <Checkbox className="checkbox" checked={true} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="right">
              <div className="content">
                <Form
                  form={form}
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  autoComplete="off"
                  style={{
                    padding: "10px",
                    visibility: selectedItem ? "visible" : "hidden",
                  }}
                >
                  <Form.Item label="Filename" name={"filename"}>
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Title" name={"fifle"}>
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Author" name={"author"}>
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Description" name={"description"}>
                    <Input.TextArea readOnly cols={3} />
                  </Form.Item>
                  <Form.Item label="URL" name={"url"}>
                    <Input.TextArea readOnly cols={3} />
                  </Form.Item>
                </Form>
              </div>
              <div className="bottom">
                <Button
                  type="primary"
                  onClick={handleConfirm}
                  disabled={!selectedItem}
                  style={{ width: "120px" }}
                >
                  插入媒体
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="middle">
              <h3>从网址插入媒体</h3>
              <div className="content">
                <Input
                  placeholder="Please input url"
                  value={selectedItem?.url}
                  onChange={handleInputUrlChange}
                />
                {selectedItem?.url && (
                  <Image
                    style={{
                      marginTop: "10px",
                      maxWidth: "700px",
                    }}
                    src={selectedItem?.url}
                    preview={false}
                  ></Image>
                )}
              </div>
            </div>
            <div className="right">
              <div className="content" style={{
                backgroundColor: '#fff'
              }}></div>
              <div className="bottom">
                <Button
                  type="primary"
                  onClick={handleConfirm}
                  disabled={!selectedItem}
                  style={{ width: "120px" }}
                >
                  插入媒体
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default MediaDialog;
