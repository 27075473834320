export const newStudentGradeOptions = [
  {
    value: "K1",
    label: "K1",
  },
  {
    value: "K2",
    label: "K2",
  },
  {
    value: "K3",
    label: "K3",
  },
  {
    value: "P1",
    label: "P1",
  },
  {
    value: "P2",
    label: "P2",
  },
  {
    value: "P3",
    label: "P3",
  },
  {
    value: "P4",
    label: "P4",
  },
  {
    value: "P5",
    label: "P5",
  },
  {
    value: "P6",
    label: "P6",
  },
  {
    value: "S1",
    label: "S1",
  },
  {
    value: "S2",
    label: "S2",
  },
  {
    value: "S3",
    label: "S3",
  },
  {
    value: "S4",
    label: "S4",
  },
  {
    value: "S5",
    label: "S5",
  },
  {
    value: "S6",
    label: "S6",
  },
];

export const genderOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
];
