import { Button, Col, Form, message, Row, Space } from "antd";
import classNames from "classnames";
import { useRef, useState } from "react";
import {
  ISaveAppointmentParams,
  saveAppointmentApi,
} from "../../../api/appointment";
import {
  getStudentCourseMasterStagesApi,
  getStudentCoursesMaster,
  getStudentCourses,
  IStudentCourseMasterRecommended,
  IStudentCourseSchool,
  IStudentCoursesMasterRes,
  IStudentCoursesRes,
  IStudentCourseRecommended,
} from "../../../api/student";
import CommonSelect from "../../../components/common-select/CommonSelect";
import { useDialog } from "../../../hooks/useDialog";
import styles from "../Signup.module.scss";
import MarkAppointment from "./MarkAppointment";
import { LeftOutlined } from "@ant-design/icons";
import ApplyStudentCourseStage from "./ApplyStudentCourseStage";

export interface ISelectSchoolApplyCourseProp {
  student_id: string;
  students: IStudentCourseSchool[];
  new_student_grade: string;
}

const SelectSchoolApplyCourse = (props: ISelectSchoolApplyCourseProp) => {
  const [form] = Form.useForm();
  const [filterBy, setFilterBy] = useState<Record<string, any>>({});
  const dialog = useDialog();
  const [coursesMaster, setCoursesMaster] = useState<IStudentCoursesRes>({
    recommended: [],
    others: [],
  });
  const onValuesChange = (values: any) => {
    const fixedValues: Record<string, any> = {};
    for (let key in values) {
      fixedValues[key] = values[key] === null ? undefined : values[key];
    }
    setFilterBy((state) => {
      return {
        ...state,
        ...fixedValues,
      };
    });
    setCoursesMaster({
      recommended: [],
      others: [],
    });
  };

  const handleApplyNewCourse = async (values: any) => {
    try {
      let res = await getStudentCourses({
        student_id: props.student_id,
        school_id: values.school_id,
        new_student_grade: props.new_student_grade,
      });
      setCoursesMaster(res.data);
    } catch (error) {
      console.error(error);
      message.error("Error");
    }
  };

  const markAppointmentRef = useRef<typeof MarkAppointment>();
  const handleAppoint = () => {
    const content = <MarkAppointment ref={markAppointmentRef} />;
    dialog.openDialog({
      title: "Mark Appointment",
      content,
      onOk: async (done: Function) => {
        if (markAppointmentRef.current) {
          const { validateFields, getFormData } = (
            markAppointmentRef.current as any
          ).getCommonFormInstance();
          try {
            await validateFields();
            const formData: ISaveAppointmentParams = getFormData();
            try {
              let res = await saveAppointmentApi({
                date: formData.date,
                time: formData.time,
                school_id: formData.school_id,
                course_master_id: formData.course_master_id,
                name: formData.name,
                age: formData.age,
                mobile: formData.mobile,
                remarks: formData.remarks || "",
              });
              message.success("Success");
              done();
            } catch (e: any) {
              console.error(e);
              message.error(e?.response?.data?.detail || "Error");
            }
          } catch {}
        }
      },
    });
  };

  const handleCourseMasterApply = async (
    item: IStudentCourseRecommended
  ) => {
    dialog.openDialog({
      title: "Apply Course Stage",
      content: (
        <ApplyStudentCourseStage
          student_id={props.student_id}
          new_student_grade={props.new_student_grade}
          course_id={item.course_id}
          onAppoint={handleAppoint}
          school_id={filterBy.school_id}
        />
      ),
      onOk: (done: Function) => {
        done();
      },
    });
  };

  return (
    <div>
      <Form
        className={classNames({
          [styles.page]: true,
          ["signup-page-school-filter"]: true,
        })}
        layout="inline"
        form={form}
        labelWrap={true}
        onValuesChange={onValuesChange}
        onFinish={handleApplyNewCourse}
      >
        <Space wrap>
          <Form.Item label="Select School" name="school_id">
            <CommonSelect
              width="280px"
              placeholder="Please Select"
              className={"school-select"}
              options={props.students}
              valueKey="school_id"
              labelKey="school_name"
            ></CommonSelect>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!filterBy.school_id}
          >
            Apply New Course
          </Button>
        </Space>
      </Form>
      {coursesMaster.recommended && (
        <Row>
          <div className={styles.blockTitle}>Courses Recommends</div>
          {coursesMaster.recommended.map((item) => {
            return (
              <Col span={24} key={item.course_id}>
                <div className={styles.blockItem}>
                  <div className={styles.title}>{item.name}</div>
                  <div className={styles.content}>{item.info}</div>
                  <div className={styles.buttons}>
                    <Space>
                      {item.can_appoint && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => handleAppoint()}
                        >
                          Appoint
                        </Button>
                      )}
                      {item.can_apply ? (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => handleCourseMasterApply(item)}
                        >
                          Apply
                        </Button>
                      ) : (
                        <Button disabled type="default" size="small">
                          Not Available
                        </Button>
                      )}
                    </Space>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      {coursesMaster.others && (
        <Row>
          <div className={styles.blockTitle}>Other Courses</div>
          {coursesMaster.others.map((item) => {
            return (
              <Col span={24} key={item.course_id}>
                <div className={styles.blockItem}>
                  <div className={styles.title}>{item.name}</div>
                  <div className={styles.content}>{item.info}</div>
                  <div className={styles.buttons}>
                    <Space>
                      {item.can_appoint && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => handleAppoint()}
                        >
                          Appoint
                        </Button>
                      )}
                      {item.can_apply ? (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => handleCourseMasterApply(item)}
                        >
                          Apply
                        </Button>
                      ) : (
                        <Button disabled type="default" size="small">
                          Not Available
                        </Button>
                      )}
                    </Space>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default SelectSchoolApplyCourse;
